import React, { Fragment } from "react";
import { graphql, Link }         from 'gatsby'
import Article             from '../components/Article'
import Header              from '../components/Header.js';
import { GlobalStyle }     from "../components/Commons";
import siteConfig          from "../../data/siteConfig";

class BlogPostTemplate extends React.Component {
    render() {
        const post               = this.props.data.markdownRemark;
        const { previous, next } = this.props.pageContext;

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    fontFamily: 'georgia, "times new roman", serif',
                    fontSize: 16,
                    color: '#333',
                    paddingBottom: 60,
                }}
            >
                <Header/>
                <Article post={post}/>


                <div style={{marginTop: 20, marginBottom: 20, opacity: 0.4, backgroundColor: 'white', height: 12, borderBottom: '1px solid grey', width: 50}}>
                </div>
            </div>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        slug
        language
      }
    }
  }
`
